import React, { Component } from "react";
import "./fonts";
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  RedditIcon
} from "react-share";

const icons = {
  settings: {
    src: "settings_icon.png",
    key: "settings",
    height: 140
  },

  share: {
    src: "share_icon.png",
    key: "share",
    height: 150
  },

  contact: {
    src: "contact_icon.png",
    key: "contact",
    height: 150
  },

  info: {
    src: "info_icon.png",
    key: "info",
    height: 200
  }
};

class Icon extends Component {
  state = {
    hovered: false
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          width: 35,
          height: 35
          //backgroundColor: "yellow"
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            color: "rgb(138, 138, 138)",
            alignItems: "center",
            justifyContent: "center",
            //cursor: "grab",
            filter: "brightness(" + (this.state.hovered ? 0.6 : 1) + ")"
          }}
          onMouseEnter={() => {
            this.setState({ hovered: true });
            this.props.onEnter(this.props.icon.key);
          }}
          onMouseLeave={() => {
            this.setState({ hovered: false });
            this.props.onLeave(this.props.icon.key);
          }}
          onClick={() => this.props.onClick(this.props.icon.key)}
        >
          <img width="100%" height="100%" src={this.props.icon.src} />
        </div>
      </div>
    );
  }
}

class Info extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          left: 0
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            height: 40,
            width: "100%",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {"INFO"}
        </div>
        <div
          style={{
            position: "absolute",
            top: 50,
            left: 20,
            height: 40,
            width: 360,
            color: "rgb(64, 64, 64)",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "PasseroOne-Regular, Passero One",
            fontSize: 25
          }}
        >
          {
            "Wordle Stars is an expanded clone of the beloved classic. Create, solve, and impress the world with your ingenuity!"
          }
        </div>
      </div>
    );
  }
}

class Setting extends Component {
  state = {
    value: ""
  };

  onSubmit(e) {
    e.preventDefault();
    if (this.state.value == "") return;
    this.props.onNewAlias(this.state.value);
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          left: 0
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            height: 40,
            width: "100%",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {"SETTINGS"}
        </div>
        <div
          style={{
            position: "absolute",
            top: 60,
            left: "5%",
            height: 40,
            width: "90%",
            outline: "3px solid rgb(161, 161, 161)",
            borderRadius: 5,
            overflow: "hidden"
          }}
        >
          <form onSubmit={this.onSubmit.bind(this)}>
            <input
              type="text"
              name="name"
              value={this.state.value}
              onChange={event => {
                this.state.value = event.target.value;
                this.setState({});
              }}
              placeholder="Set nickname"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                paddingLeft: 10,
                paddingRight: 10,
                border: "none",
                outline: "none",
                fontSize: 20,
                backgroundColor: "rgb(252, 252, 236)", // "transparent",
                fontFamily: "DenkOne-Regular, Denk One"
              }}
            />
          </form>
        </div>
      </div>
    );
  }
}

const buttonStyle = {
  position: "relative",
  width: "auto",
  height: 63,
  borderRadius: 3,
  outline: "solid 3px rgb(164, 164, 164)",
  overflow: "hidden",
  cursor: "grab"
};

class Share extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          left: 0
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            height: 40,
            width: "100%",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {"SHARE"}
        </div>
        <div
          style={{
            position: "absolute",
            top: 60,
            left: 50,
            height: 120,
            width: 300,
            //backgroundColor: "rgb(204, 200, 193)",
            //outline: "4px solid black",
            //borderRadius: 5,
            display: "flex",
            justifyContent: "space-evenly",
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 30,
            color: "rgb(38, 38, 38)",
            opacity: 0.8
          }}
        >
          <div style={buttonStyle}>
            <FacebookShareButton
              children={<FacebookIcon />}
              url="https://wordlestars.com"
            />
          </div>
          <div style={buttonStyle}>
            <TwitterShareButton
              children={<TwitterIcon />}
              url="https://wordlestars.com"
            />
          </div>
          <div style={buttonStyle}>
            <RedditShareButton
              children={<RedditIcon />}
              url="https://wordlestars.com"
            />
          </div>
        </div>
      </div>
    );
  }
}

class Contact extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          left: 0
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            height: 40,
            width: "100%",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {"CONTACT"}
        </div>
        <div
          style={{
            position: "absolute",
            top: 50,
            left: 25,
            height: 40,
            width: 350,
            color: "rgb(92, 105, 198)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "PasseroOne-Regular, Passero One",
            fontSize: 25
          }}
        >
          {"EMAIL: wordlestars@gmail.com"}
        </div>
        <div
          style={{
            position: "absolute",
            top: 90,
            left: 50,
            height: 40,
            width: 300,
            color: "rgb(191, 79, 79)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "PasseroOne-Regular, Passero One",
            fontSize: 25
          }}
        >
          {"twitter: @wordlestars"}
        </div>
      </div>
    );
  }
}

export default class Settings extends Component {
  state = {
    active: "none",
    expandedHeight: 0,
    expandedWidth: 0
  };

  onClick(key) {
    // no op
  }

  onEnter(key) {
    this.active = true;
    this.setState({
      active: key,
      expandedHeight: icons[key].height,
      expandedWidth: 400
    });
  }

  onLeave(key) {
    this.active = false;
    setTimeout(() => this.maybeRelease(), 50);
  }

  maybeRelease() {
    if (!this.inRelease && !this.active) this.resetRelease();
  }

  resetRelease() {
    this.inRelease = false;
    this.setState({ expandedHeight: 0, expandedWidth: 0 });
  }

  enterRelease() {
    this.inRelease = true;
  }

  leaveRelease() {
    this.resetRelease();
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 25,
          left: 0,
          height: 60,
          width: 300,
          borderRadius: 35,
          backgroundColor: "rgb(244, 238, 228)",
          outline: "3px solid rgb(173, 173, 173)",
          transformOrigin: "center top",
          transform:
            "translate(" +
            (window.innerWidth / 2 - 300 / 2) +
            "px, " +
            "0px) scale(" +
            (window.innerHeight / 60) * 0.07 +
            ")",
          display: "flex",
          color: "rgb(138, 138, 138)",
          alignItems: "center",
          justifyContent: "space-evenly",
          fontFamily: "PasseroOne-Regular, Passero One",
          fontSize: 45
        }}
      >
        {Object.keys(icons).map((key, id) => {
          return (
            <Icon
              key={key}
              icon={icons[key]}
              onClick={this.onClick.bind(this)}
              onEnter={this.onEnter.bind(this)}
              onLeave={this.onLeave.bind(this)}
            />
          );
        })}
        <div
          style={{
            position: "absolute",
            top: 75,
            left: "50%", //-50,
            height: this.state.expandedHeight, //200,
            width: this.state.expandedWidth, //400,
            borderRadius: 35,
            backgroundColor: "rgb(244, 238, 228)",
            outline:
              this.state.expandedHeight == 0
                ? ""
                : "3px solid rgb(173, 173, 173)",
            transition: "height ease-in-out 0.1s, width ease-in-out 0.3s",
            overflow: "hidden",
            display: "flex",
            color: "rgb(138, 138, 138)",
            //alignItems: "center",
            //justifyContent: "center",
            transform: "translateX(-50%)",
            fontSize: 30
          }}
          onMouseEnter={this.enterRelease.bind(this)}
          onMouseLeave={this.leaveRelease.bind(this)}
        >
          {this.state.active == "settings" ? (
            <Setting
              identity={this.props.identity}
              alias={this.props.alias}
              onNewAlias={this.props.onNewAlias}
            />
          ) : this.state.active == "share" ? (
            <Share />
          ) : this.state.active == "contact" ? (
            <Contact />
          ) : this.state.active == "info" ? (
            <Info />
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}
