import Crypto from "./CryptoUtils";

export default class Identity {
  constructor() {
    this.reloadKeys();
  }

  reloadKeys() {
    this.keys = {};

    let publicKey = window.localStorage.getItem("publicKey");
    let privateKey = window.localStorage.getItem("privateKey");

    if (!publicKey || !privateKey) {
      console.log("creating new keys");
      this.keys = Crypto.createKeyPair();
      // FIXME: somehow this is executed async and above linne is not getting printed
      // even if keys are not in local storage
      window.localStorage.setItem("publicKey", this.keys.publicKey);
      window.localStorage.setItem("privateKey", this.keys.privateKey);
    } else {
      console.log("keys loaded from storage");
      this.keys = { publicKey, privateKey };
    }

    this.id = Crypto.toAddress(this.keys.publicKey);
    console.log(this.keys, this.id);
  }
}
