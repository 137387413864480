import React, { Component } from "react";

export default class BigButton extends Component {
  state = {
    button: ""
  };
  render() {
    return (
      <div
        style={{
          position: "absolute",
          height: 70,
          width: 200,
          borderRadius: 10,
          outline: "4px solid rgb(172, 172, 172)",
          fontFamily: "DenkOne-Regular, Denk One",
          fontSize: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(103, 103, 103)",
          cursor: "grab",
          userSelect: "none",
          transition: "transform ease-in-out 0.15s",
          transform:
            "scale(" + (this.state.button == "hovered" ? 1.07 : 1) + ")",
          backgroundColor:
            this.state.button == "pressed"
              ? "rgb(218, 167, 100)"
              : this.state.button == "hovered"
              ? "rgb(245, 208, 148)"
              : "rgb(247, 228, 158)"
        }}
        onMouseEnter={() => {
          this.setState({ button: "hovered" });
        }}
        onMouseLeave={() => {
          this.setState({ button: "" });
        }}
        onMouseDown={() => {
          this.setState({ button: "pressed" });
        }}
        onMouseUp={() => {
          this.setState({ button: "hovered" });
        }}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </div>
    );
  }
}
