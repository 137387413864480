import React, { Component } from "react";
import "./fonts";

export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 10,
            height: 40,
            width: "100%",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 20,
            color: "rgb(66, 66, 66)"
          }}
        >
          {this.props.identity.id == undefined
            ? "Your player id has not been created yet."
            : "Your player id: " +
              (this.props.alias != undefined
                ? this.props.alias
                : this.props.identity.id.substr(0, 7))}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            height: 40,
            width: 300,
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            fontFamily: "Acme-Regular, Acme",
            fontSize: 15
          }}
        >
          <span
            style={{
              whiteSpace: "pre"
            }}
          >
            {"Developed by "}
          </span>
          <a
            href={"https://github.com/dennis3124"}
            style={{
              whiteSpace: "pre",
              color: "rgb(115, 115, 115)",
              fontSize: 18
            }}
          >
            {"dennis3124"}
          </a>
          <span
            style={{
              whiteSpace: "pre"
            }}
          >
            {" and "}
          </span>
          <a
            href={"https://github.com/balabasik"}
            style={{
              whiteSpace: "pre",
              color: "rgb(115, 115, 115)",
              fontSize: 18
            }}
          >
            {"balabasik"}
          </a>
          {"."}
        </div>
      </React.Fragment>
    );
  }
}
