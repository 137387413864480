import React, { Component } from "react";
import "./fonts";
import BigButton from "./BigButton";
import Settings from "./Settings";
import LeagueMark from "./LeagueMark";

class WinnerList extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 140,
          left: 0,
          height: 800,
          width: "100%",
          fontFamily: "DenkOne-Regular, Denk One",
          fontSize: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(103, 103, 103)"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: 70,
            width: "100%",
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(103, 103, 103)"
          }}
        >
          {this.props.text}
        </div>
        <div
          style={{
            position: "absolute",
            top: 60,
            left: 0,
            height: 720,
            width: "100%",
            fontSize: 20,
            color: "rgb(103, 103, 103)",
            overflowY: "auto"
          }}
        >
          {this.props.list.map(item => {
            let key = item[0];
            let value = item[1];
            return (
              <div
                key={key}
                style={{
                  position: "relative",
                  height: 40,
                  width: "100%",
                  fontFamily: "DenkOne-Regular, Denk One",
                  fontSize: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgb(46, 46, 46)"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    fontFamily: "DenkOne-Regular, Denk One",
                    fontSize: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(46, 46, 46)"
                  }}
                >
                  {":"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "50%",
                    right: "50%",
                    paddingRight: 10,
                    fontFamily: "DenkOne-Regular, Denk One",
                    fontSize: 20,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    color: "rgb(46, 46, 46)"
                  }}
                >
                  {key}
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "50%",
                    left: "50%",
                    paddingLeft: 10,
                    fontFamily: "DenkOne-Regular, Denk One",
                    fontSize: 20,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(46, 46, 46)"
                  }}
                >
                  {value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default class Landing extends Component {
  state = {
    topCreators: [],
    topSolvers: [],
    titleShift: 0,
    titleWidth: 290
  };

  componentDidMount() {
    //setTimeout(() => this.setState({ titleShift: 0, titleWidth: 290 }), 200);
    /*setTimeout(
      () => this.setState({ titleShift: -195, titleWidth: 105 }),
      2200
    );*/
    this.periodicTopListUpdate();
  }

  periodicTopListUpdate() {
    this.updateTopList();
    this.topListTimeout = setTimeout(() => this.periodicTopListUpdate(), 60000);
  }

  updateTopList() {
    this.props.be.getTopList((solvers, creators) => {
      let topSolvers = [];
      let topCreators = [];
      for (let record of solvers) {
        topSolvers.push([
          record.player_alias
            ? record.player_alias
            : record.person_id.substring(0, 7),
          record.number_of_puzzle_solves
        ]);
      }
      for (let record of creators) {
        topCreators.push([
          record.player_alias
            ? record.player_alias
            : record.person_id.substring(0, 7),
          record.number_of_tries_to_solve_your_puzzle
        ]);
      }
      this.setState({ topSolvers, topCreators });
    });
  }

  onCreate() {
    this.props.onCreate();
  }

  onSolve() {
    this.props.onSolve();
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          overflow: "hidden"
        }}
      >
        <div
          style={{
            position: "absolute",
            width: 1000,
            height: 800,
            left: 0,
            top: 0,
            //backgroundColor: "rgb(203, 203, 203)",
            transform:
              "translate(" +
              (window.innerWidth / 2 - 1000 / 2) +
              "px, " +
              (window.innerHeight / 2 - 800 / 2) +
              "px) scale(" +
              window.innerHeight / 800 +
              ")"
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "45%",
              height: "100%",
              top: 15,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
              //backgroundColor: "red"
            }}
          >
            <div
              style={{
                position: "relative",
                top: 10,
                width: 600,
                height: 600,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgb(210, 210, 210)",
                borderRadius: 10
                //alignItems: "center"
              }}
            >
              <WinnerList text="TOP CREATORS" list={this.state.topCreators} />
              <div
                style={{
                  position: "relative",
                  top: 50,
                  height: 70,
                  width: 200
                }}
              >
                <BigButton text="CREATE" onClick={this.onCreate.bind(this)} />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "45%",
              height: "100%",
              top: 15,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
              //backgroundColor: "red"
            }}
          >
            <div
              style={{
                position: "relative",
                top: 10,
                width: 600,
                height: 600,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgb(210, 210, 210)",
                borderRadius: 10
                //alignItems: "center"
              }}
            >
              <WinnerList text="TOP SOLVERS" list={this.state.topSolvers} />
              <div
                style={{
                  position: "relative",
                  top: 50,
                  height: 70,
                  width: 200
                }}
              >
                <BigButton text="SOLVE" onClick={this.onSolve.bind(this)} />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 20,
            left: 20,
            height: 70,
            width: this.state.titleHovered ? 290 : 105, //this.state.titleWidth,
            borderRadius: 35,
            backgroundColor: "rgb(228, 228, 244)",
            outline: "3px solid rgb(173, 173, 173)",
            transformOrigin: "left top",
            transform: "scale(" + (window.innerHeight / 60) * 0.07 + ")",
            transition: "width ease-in-out 0.3s"
          }}
          onMouseEnter={() => {
            this.setState({ titleHovered: true });
          }}
          onMouseLeave={() => {
            this.setState({ titleHovered: false });
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 9,
              left: 22,
              height: 60,
              width: 60
            }}
          >
            <img width="100%" height="100%" src="logo2.png" />
          </div>
          <div
            style={{
              position: "absolute",
              top: -2,
              left: 83,
              height: 80,
              width: "100%",
              overflow: "hidden"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: 80,
                width: 400,
                display: "flex",
                color: "rgb(138, 138, 138)",
                alignItems: "center",
                fontFamily: "PasseroOne-Regular, Passero One",
                fontSize: 44,
                transform:
                  "translateX(" + (this.state.titleHovered ? 0 : -195) + "px)",
                transition: "transform ease-in-out 0.3s"
              }}
            >
              {"ordle Stars"}
            </div>
          </div>
        </div>
        <Settings
          alias={this.props.alias}
          identity={this.props.identity}
          onNewAlias={this.props.onNewAlias}
        />
        <LeagueMark
          simple={false}
          league={this.props.league}
          onLeague={this.props.onLeague}
        />
      </div>
    );
  }
}
