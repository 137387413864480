// TODO: this will be replaced by web3
import secp256k1 from "secp256k1";
import { Keccak } from "sha3";

// Convert a hex string to a byte array
function HexToBytes(hex) {
  for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
  return Uint8Array.from(bytes);
}

function HexToBytes32(hex) {
  for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
  while (bytes.length < 32) {
    bytes.unshift(0);
  }
  return Uint8Array.from(bytes);
}

function CreatePrivateKey() {
  const arr = "0123456789abcdef";
  let key = "";
  for (let i = 0; i < 64; i++) key += arr[Math.floor(Math.random() * 16)];
  return key;
}

// Convert a byte array to a hex string
function BytesToHex(bytes) {
  for (var hex = [], i = 0; i < bytes.length; i++) {
    var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
    hex.push((current >>> 4).toString(16));
    hex.push((current & 0xf).toString(16));
  }
  return hex.join("");
}

function CreateKeyPair() {
  let privateKey = CreatePrivateKey();
  // note: publicKey has 33 bytes in compressed form (i.e., 66 hex characters)
  let publicKey = BytesToHex(secp256k1.publicKeyCreate(HexToBytes(privateKey)));
  return { publicKey, privateKey };
}

function GetTime() {
  return Math.round(new Date().getTime() / 1000);
}

function HexToBase64(hex) {
  return btoa(
    hex
      .match(/\w{2}/g)
      .map(function(a) {
        return String.fromCharCode(parseInt(a, 16));
      })
      .join("")
  );
}

// tosign is
function Sign(data, keys) {
  // keys = {publicKey, privateKey} in hex
  // NOTE: will add the issuer field into the data before computing string
  // NOTE: will add 1 hour expiration time
  // NOTE: signature is base64 encoded
  data.iss = keys.publicKey; // 40 character hex string
  data.exp = GetTime() + 3600;
  let dataJson = JSON.stringify(data);
  const hash = new Keccak(256);
  let signObj = secp256k1.ecdsaSign(
    hash.update(dataJson).digest(),
    HexToBytes(keys.privateKey)
  );
  //console.log(signObj.signature);
  //let signature = Buffer.from(signObj.signature).toString("base64");
  return {
    data: dataJson,
    signature: HexToBase64(BytesToHex(signObj.signature))
  };
}

function Decompress(publicKey) {
  var decompressed = BytesToHex(
    secp256k1.publicKeyConvert(HexToBytes(publicKey), false)
  );
  return decompressed;
}

function ToAddress(publicKey) {
  // NOTE: nothing else works!
  // having ascii/utf8 gives malformed strings and wrong results!!!!
  let decompressed = Decompress(publicKey);
  const hash = new Keccak(256);
  return hash
    .update(decompressed.slice(2), "hex")
    .digest()
    .slice(-20)
    .toString("hex");
}

const Crypto = {
  createKeyPair: CreateKeyPair,
  sign: Sign,
  toAddress: ToAddress
};

export default Crypto;
