import React, { Component } from "react";

export default class SmallButton extends Component {
  state = {
    button: ""
  };
  render() {
    return (
      <div
        style={{
          position: "absolute",
          height: 60,
          width: 150,
          borderRadius: 8,
          outline: "4px solid rgb(172, 172, 172)",
          fontFamily: "DenkOne-Regular, Denk One",
          fontSize: 25,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(103, 103, 103)",
          cursor: "grab",
          userSelect: "none",
          transition: "transform ease-in-out 0.15s",
          transform:
            "scale(" + (this.state.button == "hovered" ? 1.07 : 1) + ")",
          backgroundColor:
            this.state.button == "pressed"
              ? "rgb(100, 190, 218)"
              : this.state.button == "hovered"
              ? "rgb(148, 207, 245)"
              : "rgb(176, 227, 255)"
        }}
        onMouseEnter={() => {
          this.setState({ button: "hovered" });
        }}
        onMouseLeave={() => {
          this.setState({ button: "" });
        }}
        onMouseDown={() => {
          this.setState({ button: "pressed" });
        }}
        onMouseUp={() => {
          this.setState({ button: "hovered" });
        }}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </div>
    );
  }
}
