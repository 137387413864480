import React, { Component } from "react";
import "./fonts";

export default class LeagueMark extends Component {
  state = {
    funHovered: false,
    proHovered: false
  };
  render() {
    return this.props.simple ? (
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          height: 70,
          width: 70,
          borderRadius: 10,
          backgroundColor:
            this.props.league == "fun"
              ? "rgb(212, 230, 255)"
              : "rgb(255, 187, 187)",
          outline: "3px solid rgb(173, 173, 173)",
          transformOrigin: "right top",
          transform: "scale(" + (window.innerHeight / 60) * 0.07 + ")",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Iceland-Regular, Icelands",
          fontSize: 30,
          fontWeight: "bold",
          color: "rgb(117, 117, 117)"
        }}
      >
        {this.props.league == "fun" ? "FUN" : "PRO"}
      </div>
    ) : (
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          height: 70,
          width: 180,
          borderRadius: 35,
          backgroundColor: "rgb(228, 244, 229)",
          outline: "3px solid rgb(173, 173, 173)",
          transformOrigin: "right top",
          transform: "scale(" + (window.innerHeight / 60) * 0.07 + ")"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -3,
            height: "55%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Iceland-Regular, Icelands",
            fontSize: 28,
            fontWeight: "bold",
            color: "rgb(157, 157, 157)"
          }}
        >
          {"LEAGUE"}
        </div>
        <div
          style={{
            position: "absolute",
            left: 27,
            bottom: 7,
            height: 30,
            width: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Iceland-Regular, Icelands",
            fontSize: 30,
            fontWeight: "bold",
            color: "rgb(255, 255, 255)",
            borderRadius: 3,
            backgroundColor: "rgb(125, 174, 246)",
            cursor: "grab",
            userSelect: "none",
            outline:
              this.props.league == "fun" ? "3px solid rgb(130, 127, 114)" : "",
            transform: this.state.funHovered ? "scale(1)" : "scale(0.9)",
            //opacity: this.state.league == "pro" ? "" : 0.6,
            transition: "transform ease-in-out 0.1s"
          }}
          onMouseEnter={() => {
            this.setState({ funHovered: true });
          }}
          onMouseLeave={() => {
            this.setState({ funHovered: false });
          }}
          onClick={() => {
            this.props.onLeague("fun");
          }}
        >
          {"FUN"}
        </div>
        <div
          style={{
            position: "absolute",
            right: 27,
            bottom: 7,
            height: 30,
            width: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Iceland-Regular, Icelands",
            fontSize: 30,
            fontWeight: "bold",
            color: "rgb(255, 255, 255)",
            borderRadius: 3,
            backgroundColor: "rgb(246, 125, 125)",
            cursor: "grab",
            userSelect: "none",
            outline:
              this.props.league == "pro" ? "3px solid rgb(112, 112, 112)" : "",
            transform: this.state.proHovered ? "scale(1)" : "scale(0.9)",
            //opacity: this.state.league == "pro" ? "" : 0.6,
            transition: "transform ease-in-out 0.1s"
          }}
          onMouseEnter={() => {
            this.setState({ proHovered: true });
          }}
          onMouseLeave={() => {
            this.setState({ proHovered: false });
          }}
          onClick={() => {
            // TODO: uncomment once PRO league is ready!
            //this.props.onLeague("pro");
          }}
        >
          {"PRO"}
        </div>
      </div>
    );
  }
}
